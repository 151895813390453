import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Deficit Deadlifts 3×12 (standing on 45-25`}{`#`}{` bumper, both heads of DB
touch ground)`}</p>
    <p>{`Hollow Rocks 3x:45`}</p>
    <p>{`then,`}</p>
    <p>{`200m Run`}</p>
    <p>{`15-DB Thrusters (35/25’s)(rx+ 50/35’s)`}</p>
    <p>{`200m Run`}</p>
    <p>{`25-DB Burpee Deadlifts`}</p>
    <p>{`200m Run`}</p>
    <p>{`35-KB SDHP’s`}</p>
    <p>{`200m Run`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      